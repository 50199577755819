<template>
	<div class="content-wrap">
		<div class="section-top">
			<div class="picture">
				<div class="img-wrap">
					<img class="img" :src="require('../../assets/icon-success'+(appno==1?'':appno)+'.png')" alt="成功">
					<span class="s-17">请您完成签名</span>
				</div>
			</div>
		</div>
		<div class="section-bottom">
			<van-button
				class="btn"
				type="primary"
				block
				@click="navTo"
			>手写签名
			</van-button>
		</div>
		<van-action-sheet
			v-model="showViewer"
			:closeable="false"
			title="温馨提示"
			cancel-text="手写签名"
			@cancel="openSign"
		>
			<div class="content" v-html="viewerContent"></div>
		</van-action-sheet>
		<!-- 手写板 -->
		<van-popup v-model="showEsign" position="top" :style="{ height: '40%' }">
			<vue-esign ref="esign" style="border:1px #ccc solid;"
					   :width="esign.width"
					   :height="esign.height"
					   :isCrop="esign.isCrop"
					   :lineWidth="esign.lineWidth"
					   :lineColor="esign.lineColor"
					   :bgColor.sync="esign.bgColor" />
			<div style="margin: 15px; text-align: center">
				<div style="margin-bottom:10px;"><span style="color:#e10900;">⬆ 请您在上面灰框区域书写签名 ⬆</span></div>
				<van-button color="#1175fe" style="width:100px;" round plain type="info" size="small" native-type="button" @click="handleSignReset" :disabled="!canBeReset"> 重写 </van-button>&nbsp;
				<van-button color="#1175fe" style="width:100px;" round type="info" size="small" native-type="button" @click="handleSignSave" :loading="showSignSaveLoading" loading-text="上传中..."> 保存 </van-button>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { uploadStudentSign } from "../../api/user";
import { Toast } from "vant";
export default {
	name: 'HandwrittenSignature',
	data() {
		return {
			step: 0,
			showViewer: false,
			viewerContent: '<div style="text-align: center; height: 40px;">请您点击下方 [手写签名] 按钮开始签名<br>签名后点击 [保存] 按钮完成</div>',
			showEsign: false,
			showSignSaveLoading: false,
			canBeReset: true,
			esign: {
				width: 800,             //画布宽度
				height: 380,            //画布高度
				lineWidth: 4,           //画笔粗细
				lineColor: '#000000',   //画笔颜色
				bgColor: '',            //背景颜色
				isCrop: true            //是否裁剪
			},
		}
	},
	mounted() {
		if (this.$route.query.sign) {
			this.vertifyViewer();
		}
	},
	computed: {
		appno() {
			return this.$store.state.app.appno
		},
	},
	methods: {
		clickViewer () {
			this.showViewer = false
			this.$router.push({path: '/train'})
		},
		vertifyViewer () {
			this.showViewer = true
		},
		navTo() {
			this.vertifyViewer()
		},
		// 打开手写签名
		openSign() {
			this.showEsign = true;
		},
		// 重写签名
		handleSignReset() {
			this.$refs.esign.reset();
		},
		// 保存签名
		handleSignSave() {
			this.$refs.esign.generate().then(base64Image => {
				this.showSignSaveLoading = true;
				this.canBeReset = false;
				// 上传签名
				uploadStudentSign({"studentId": this.$store.state.user.userid, "studentSignPic": base64Image})
					.then((res) => {
						this.showSignSaveLoading = false;
						this.canBeReset = true;
						this.studentSign = res;
						this.showEsign = false;
						Toast.success('签名已保存！');
						this.clickViewer();
					})
					.catch(() => {
						this.showSignSaveLoading = false;
						this.canBeReset = true;
					});
			}).catch(err => {
				console.log(err);
				Toast.fail('请手写签名后保存');
			})
		},
	}
}
</script>
<style lang="less">
.APP01{
	.van-action-sheet__cancel{ color: #ff8506 !important;}
}
.APP02{
	.van-action-sheet__cancel{ color: #2db1ac !important;}
}
.APP03{
	.van-action-sheet__cancel{ color: #1175fe !important;}
}

</style>
<style lang="less" scoped>
	.content-wrap {
		.van-cell {
			margin-top: 10px;
			// box-shadow: 0 0 8px 0px rgba(0,0,0,0.15);
			box-shadow: 0 2px 8px 0 rgba(210, 210, 210, 0.48);
			border-radius: 8px;

			&::after {
				border: none;
			}
		}

		.van-field__left-icon {
			margin-right: 6px;
		}

		.section-top {
			.title {
				position: relative;
				font-size: 17px;
				text-align: center;

				.txt {
					position: relative;
				}

				.line {
					position: absolute;
					top: 50%;
					display: inline-block;
					width: 30px;
					height: 2px;
					background: #fca142;
					margin: -1px 0 0;

					&:nth-of-type(1) {
						left: -42px;
					}

					&:nth-of-type(2) {
						right: -42px;
					}
				}
			}

			.picture {
				margin: 50px 0;

				.desc {
					line-height: 1.5;
				}

				.img-wrap {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.img {
						display: inline-block;
						max-width: 80%;
						width: 80px;
						min-height: 40px;
						margin: 12px auto;
					}
				}
			}

		}

		.section-bottom {
			margin-top: 40px;
		}
	}
	.van-action-sheet__cancel {
		color: #E10900;
	}
</style>
